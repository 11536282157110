import React, { useState, useEffect } from "react";
import useImageLoader from "../custom_hooks/imageLoader.js";
import sanityClient from "../client.js";
import EducationHeroImage from "../images/Photo_by_Fadi_Kheir_4[optimized].jpg"
import imageUrlBuilder from "@sanity/image-url";
import SanityBlockContent from "@sanity/block-content-to-react";
import Loader from "./Loader.js";
import Footer from "./Footer.js";
import '../css/Education.css';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source);
};

// these serializers are for the formatting of block content from Sanity Studio

const serializers = {
    types: {
        block: (props) => {
            const style = props.node.style || 'normal';
            if (style === 'h1') return <h1 className="educaton-h1">{props.children}</h1>;
            if (style === 'h2') return <h2 className="education-h2">{props.children}</h2>;
            if (style === 'h3') return <h3 className="education-h3">{props.children}</h3>;
            if (style === 'blockquote') return <blockquote className="education-p">{props.children}</blockquote>;
            return <p className="education-p">{props.children}</p>;
        },
        image: (props) => (
            <div className="my-image-container">
                <img src={props.node.asset.url} alt={props.node.alt} className="my-image" />
            </div>
        ),
    },
    marks: {
        link: (props) => (
            <a href={props.mark.href} className="my-link" target="_blank" rel="noopener noreferrer">
                {props.children}
            </a>
        ),
    },
};

export default function Education() {
    const [educationalData, setEducationalData] = useState(null);
    const [imageUrlArray, setImageUrlArray] = useState([EducationHeroImage]);
    const [youTubeVideosArray, setYouTubeVideosArray] = useState(null);

    const allImagesLoaded = useImageLoader(imageUrlArray);

    useEffect(() => {
        sanityClient.fetch(`*[_type == "education"]{ images, educationBio, youTubeVideos }`)
            .then((data) => {
                setEducationalData(data);
                const fetchedImages = data[0].images.map(image => urlFor(image).url());
                setImageUrlArray(prevArray => [...prevArray, ...fetchedImages]);
                setYouTubeVideosArray(data[0].youTubeVideos);
            })
            .catch(console.error);
    }, []);

    useEffect(() => {
        if (!educationalData || !educationalData[0] || !educationalData[0].images) return;

        const styleElement = document.createElement('style');
        document.head.appendChild(styleElement);

        const imageTime = 4;
        const crossfadeDuration = 2;
        const totalImages = educationalData[0].images.length;
        const animationDuration = (imageTime + crossfadeDuration) * totalImages;
  
        const keyFrame1 = "0%";
        const keyFrame2 = `${(imageTime / animationDuration) * 100}%`;
        const keyFrame3 = `${((imageTime + crossfadeDuration) / animationDuration) * 100}%`;
        const keyFrame4 = `${100 - (crossfadeDuration / animationDuration) * 100}%`;
        const keyFrame5 = "100%";

        const keyframes = `
          @-webkit-keyframes fade {
            ${keyFrame1} { opacity: 1; }
            ${keyFrame2} { opacity: 1; }
            ${keyFrame3} { opacity: 0; }
            ${keyFrame4} { opacity: 0; }
            ${keyFrame5} { opacity: 1; }
          }
          @keyframes fade {
            ${keyFrame1} { opacity: 1; }
            ${keyFrame2} { opacity: 1; }
            ${keyFrame3} { opacity: 0; }
            ${keyFrame4} { opacity: 0; }
            ${keyFrame5} { opacity: 1; }
          }
        `;

        const animationClass = `
          .education-fading-image {
            -webkit-animation: fade ${animationDuration}s infinite;
            animation: fade ${animationDuration}s infinite;
          }
        `;

        styleElement.appendChild(document.createTextNode(keyframes));
        styleElement.appendChild(document.createTextNode(animationClass));
    }, [educationalData]);


    useEffect(() => {
        
        if (allImagesLoaded) {
          const educationHero = document.querySelector('.education-hero');
          
          if (educationHero) {
            educationHero.style.backgroundImage = `url(${EducationHeroImage})`;
          }
    
        }
      }, [allImagesLoaded, imageUrlArray]);
    
      if (!allImagesLoaded) {
        return <div><Loader loaded={false}/></div>;
      }
    

    return (
        <div className="education-page-body">
            <Loader loaded={true} />
            <div className="education-hero"></div>
            <h1 className="education-h1">Education</h1>
            <p></p>
            <div className="education-image-container">
          
            {educationalData && educationalData[0].images.map((image, index) => {
                        
                        const delayArray =[];
                        
                        for (let x=educationalData[0].images.length-1; x>=0; x--){
                          let timeElement = x * 6;
                           delayArray.push(timeElement);
                        }

                        const delay = `${delayArray[index]}s`;
                        const style = {
                          animationDelay: delay,
                          WebkitAnimationDelay: delay,
                        };
                        return (
                          
                              <img 
                              key={index} 
                              src={urlFor(image).url()} 
                              alt={`Farah Education ${index + 1}`} 
                              className="education-fading-image" 
                              style={style}
                          />
                        
                        )
                    })}  

            </div>
            <h2 className="education-h2">Education Bio</h2>
            <div className="education-text">   
              <div className="education-text-line-border"></div>
              {educationalData && educationalData.length > 0 && (
                  <SanityBlockContent
                      blocks={educationalData[0].educationBio}
                      projectId="d3dhpvc9"
                      dataset="production"
                      serializers={serializers}
                  />
              )}
            </div>
            <div className="education-youtube-container">
                    {youTubeVideosArray && youTubeVideosArray.map((video, index)=>{
                      return(
                        <div key={index} className="youtube-video" dangerouslySetInnerHTML={{ __html: video.embedLink.code }}>

                        </div>
                      )
                    })
                    }
              </div>
            <Footer />
        </div>
    );
};