import React from "react";
import '../css/navbar.css'; 
import { NavLink } from 'react-router-dom';
import logoimage from '../images/farah-logo-transparent[white][optimized].png'

const navLinkClass = (activeState) => {
    return activeState ? 'nav-link active' : 'nav-link inactive'
}

function Navbar() {
    
    

    function hamburgerClick(){
        const hamburger = document.querySelector('.hamburger');
        const navMenu = document.querySelector('.nav-menu');
        const navBar = document.querySelector('.navbar');
        hamburger.classList.toggle("active");
        navMenu.classList.toggle("active");
        hamburger.classList[1]==='active'? navBar.style.backgroundColor = "#104e58f9" : navBar.style.backgroundColor = "rgba(0, 0, 0, 0.45)";
    };

    function handleNavLinkCLick(){
        var screenWidth = window.innerWidth;
        console.log("click", screenWidth);
        if (screenWidth < 989) {
            hamburgerClick(); 
        }
    }

    window.addEventListener('resize', function() {
        const navBar = document.querySelector('.navbar');
        const navMenu = document.querySelector('.nav-menu');
        if (window.innerWidth > 990 && navMenu.classList.contains("active")) {
            navBar.style.backgroundColor = "rgba(0, 0, 0, 0.45)"
            hamburgerClick();
        } 
      });
  

  return (
    <header>
        <div className="navbar-container">
            <nav className="navbar">
                <ul className="nav-menu">
                <NavLink to="/" end className={({isActive}) => navLinkClass(isActive)} onClick={handleNavLinkCLick}>
                   Home 
                </NavLink>
                <NavLink to="/about" className={({isActive}) => navLinkClass(isActive)} onClick={handleNavLinkCLick}>
                    About
                </NavLink>
                <NavLink to="/media" className={({isActive}) => navLinkClass(isActive)} onClick={handleNavLinkCLick}>
                    Media
                </NavLink>

                <span> <img src={logoimage} className="navbar-logo-image" alt="navbar-logo"/> </span>
                <NavLink to="/calendar" className={({isActive}) => navLinkClass(isActive)} onClick={handleNavLinkCLick}>
                    Calendar
                </NavLink>
                <NavLink to="/education" className={({isActive}) => navLinkClass(isActive)} onClick={handleNavLinkCLick}>
                    Education
                </NavLink>
                <NavLink to="/contact" className={({isActive}) => navLinkClass(isActive)} onClick={handleNavLinkCLick}>
                    Contact
                </NavLink>
                </ul>
                <div className="hamburger" onClick={hamburgerClick}>
                    <span className="hamburger-bar"></span>
                    <span className="hamburger-bar"></span>
                    <span className="hamburger-bar"></span>
                </div>
            </nav>
        </div>
    </header>
   
    
  );
}

export default Navbar;