import React from 'react';
import { useForm } from 'react-hook-form';
import '../css/ContactForm.css'; // Import the CSS for styling
const ContactForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    console.log('Form data', data);
  
  };

  return (
    <div className='contact-form-flex-container'>
      <div className='contact-form'>  
      <form name="contact" method="POST">
        <input type="hidden" name="form-name" value="contact"/>
        <div className="form-group">
              <input
                type="text"
                name="name"
                id="name"
                required
                {...register('name', { required: 'Name is required' })}
              />
              <label htmlFor="name" className={errors.name ? 'error' : ''}>Name</label>
              {errors.name && <p className="error-message">{errors.name.message}</p>}
            </div>

            <div className="form-group">
              <input
                type="email"
                name="email"
                id="email"
                required
                {...register('email', {
                  required: 'Email is required',
                  pattern: {
                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                    message: 'Invalid email format',
                  },
                })}
              />
              <label htmlFor="email" className={errors.email ? 'error' : ''}>Email</label>
              {errors.email && <p className="error-message">{errors.email.message}</p>}
            </div>

            <div className="form-group">
              <textarea
              name="message"
                id="message"
                required
                {...register('message', { required: 'Message is required' })}
              ></textarea>
              <label htmlFor="message" className={errors.message ? 'error' : ''}>Message</label>
              {errors.message && <p className="error-message">{errors.message.message}</p>}
            </div>

            <button type="submit">Submit</button>

      </form>
      </div>
      <div className='booking-contact'>
        <h3>for booking inquiries:</h3>
        <p>Danielle Devlin</p>
        <p>Canis Major Music</p>
        <a href="https://canismusic.com/contact-us/" target="_blank" >https://canismusic.com/contact-us/</a>
      </div>




     
</div>
  
  );
};

export default ContactForm;
