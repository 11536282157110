import { BrowserRouter, Routes, Route} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Calendar from './components/Calendar';
import Media from './components/Media';
import Education from './components/Education';
import Contact from './components/Contact';


function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
      <Navbar />
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/media" element={<Media />} />
          <Route path="/education" element={<Education />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
    </BrowserRouter>
    
  );
}

export default App;
