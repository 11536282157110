const useImageLoader = (imageUrls) => {
  console.log("Image Loader Called");
  const loadImages = () => {
    return new Promise((resolve, reject) => {
      const loadImage = (url) => {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(url);
          image.onerror = () => reject(url);
          image.src = process.env.PUBLIC_URL + url; // Ensure correct path to public assets
        });
      };

      const loadImagePromises = imageUrls.map(loadImage);

      Promise.all(loadImagePromises)
        .then(() => resolve(true))
        .catch(() => reject(false));
    });
  };

  return loadImages;
};

export default useImageLoader;
