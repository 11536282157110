import React, { useState, useEffect } from 'react';
import '../css/Loader.css';
import Logo from '../images/Farah-Circular-Logo[optimized].png';

const Loader = ({ loaded }) => {
  const [fadeOut, setFadeOut] = useState(false);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    if (loaded) {
      // Set fade-out after 2 seconds if loaded is true
      const timer = setTimeout(() => {
        setFadeOut(true);
      }, 300);

      // Set hidden state after fade-out transition completes
      const timeout = setTimeout(() => {
        setHidden(true);
      }, 1000); // Adjust timing to match transition duration + delay

      return () => {
        clearTimeout(timer);
        clearTimeout(timeout);
      }; // Cleanup the timers if component unmounts
    }
  }, [loaded]);

  return (
    <div className={`loader-body ${fadeOut ? 'fade-out' : ''} ${hidden ? 'hidden' : ''}`}>
      <div className="loader">
        <h1>FARAH SIRAJ</h1>
        <div className="spinner">
          <img src={Logo} alt="Logo" className="logo" />
          <div className="spinner-circle"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
