import React, { useState, useEffect} from "react";
import sanityClient from "../client.js";
import ContactForm from "./ContactForm.js";
import Footer from "./Footer.js";
import Loader from "./Loader.js"
// Images and Loader Custom Hook
import useImageLoader from "../custom_hooks/imageLoader.js";
import HeroImage from '../images/Farah_Siraj_Photo_by_Richard_Ramos[optimized].jpg';
import AboutImage from "../images/Farah Siraj - Photo by Yasmina Barbero 7[optimized].jpg";
import ReviewOneImage from '../images/Photo_by_Arco_Visuales[optimized].jpg';
import VideoExcerpt from "../video/Diggi Ya Rebaba Web Clip.m4v";
import ReviewTwoImage from "../images/Farah_Siraj_Photo_by_Yasmina_Barbero_5[optimized].jpg";


export default function Home() {
    const [shortBio, setShortBio] = useState(null);
    const [embedAlbums, setEmbedAlbums] = useState(null);

// send the images to the image loader
const imageUrlArray = [
   HeroImage, AboutImage, ReviewOneImage, ReviewTwoImage
];

const allImagesLoaded = useImageLoader(imageUrlArray);


//    get the Sanity Studio Data
    useEffect(() => {
        sanityClient.fetch(`*[_type == "landingPageBio"]{
            shortbio
        }`).then((data) => {setShortBio(data[0].shortbio)})
        .catch(console.error);
    },[]);

    useEffect(() => {
        sanityClient.fetch(`*[_type == "spotifyAlbums"]{
            title,
            albums
        }`).then((data) => {setEmbedAlbums(data[0].albums)})
        .catch(console.error);
    },[]);

// check for mobile device ------------

    function isTouchDevice() {
        return 'ontouchstart' in window || navigator.maxTouchPoints;
    }
    if (isTouchDevice()) {
        console.log("User is on a touch-enabled device (likely mobile).");

        let sections = [
            document.querySelector('.hero'),
            document.querySelector('.landing-page-section'),
            document.querySelector('.selected-review-container'),
            document.querySelector('.review-landing-page-section'),
            document.querySelector('.contact-landing-page-section'),
            document.querySelector('.selected-review-container-2'),
        ];
       
        sections.forEach(section => {
            if (section) {
                section.style.backgroundAttachment = 'scroll';
            }
        });
        let reviewTwoText = document.querySelector('.selected-review-container-2 .selected-review');
        let reviewTwoContainer = document.querySelector('.selected-review-container-2');
        
        if(reviewTwoContainer){
            reviewTwoContainer.style.display = 'flex';
            reviewTwoContainer.style.flexDirection = 'column';
            reviewTwoContainer.style.justifyContent = 'flex-end';
            
        }

        if(reviewTwoText){
            reviewTwoText.style.background= 'linear-gradient(to top, rgb(0, 0, 0) 5%, rgba(255, 255, 255, 0))';
        }
       
       

    } else {
        console.log("User is on a device without touch support (likely desktop).");
     
    }

    useEffect(() => {
        if (allImagesLoaded) {
          // When all images are loaded, manipulate DOM elements
          const heroElement = document.querySelector('.hero');
          const selectedReviewContainer = document.querySelector('.selected-review-container');
          const selectedReviewContainerTwo = document.querySelector('.selected-review-container-2');
          if (heroElement) {
            heroElement.style.backgroundImage = `url(${HeroImage})`;
          }
          if (selectedReviewContainer) {
            selectedReviewContainer.style.backgroundImage = `url(${ReviewOneImage})`;
          }
          if (selectedReviewContainerTwo) {
            selectedReviewContainerTwo.style.backgroundImage = `url(${ReviewTwoImage})`;
          }
        }
      }, [allImagesLoaded]);
    
      if (!allImagesLoaded) {
        return <div><Loader loaded={false}/></div>;
      }
      
    return(
        <div id="home">
            <Loader loaded={true} />
            <div className="hero">
                <div className="hero-text">
                    <div className="title" itemProp="name">FARAH SIRAJ</div>
                </div>
            </div> 
            <div className="landing-page-section">
                <h2>About</h2>
                <div className="about-container" >
                    <div className="left-container">
                    <img src={AboutImage} className="about-image"/>
                    </div>
                    <div className="vertical-line"></div>
                    <div className="right-container">
                        <p>{shortBio}
                        </p>
                        <a href="./about">read the full bio</a>
                    </div>
                </div>
            </div>
            <div className="review-landing-page-section">
                <div className="selected-review-container">
                    <div className="selected-review">
                        <quote>“The Norah Jones of the Middle East”</quote><p  style={{ textAlign: 'left' }}>- TimeOut New York</p>
                    </div>
                </div>
            </div>
            <div className="video-landing-page-section">
                <div className="video-container">
                    <div>
                        <video autoPlay muted loop playsInline>
                            <source src={VideoExcerpt} type="video/mp4" />
                            <source src={VideoExcerpt} type="video/ogg" />
                            <p>Sorry, Your Browser Doesn't Support Embedded Videos. Here's the <a href="https://www.youtube.com/@FarahSirajMusic">Link to the video</a></p>
                        </video>
                    </div>
            </div>


            <div className="audio-player-landing-page-section">
                <div className="audio-players-container">
                        {/* currently set only to return the first album of the array */}
                    {embedAlbums && embedAlbums.length > 0 && (
                        <div>
                            <div><h3>{embedAlbums[0].title}</h3></div>
                            <div dangerouslySetInnerHTML={{ __html: embedAlbums[0].embedLink.code }} />
                        </div>
                       
                    )}
                    </div>
                </div>
            </div>


                <div className="review-landing-page-section">
                    <div className="selected-review-container-2">
                        <div className="selected-review">
                            <quote>“Vivacious arabian flamenco jazz”</quote><p  style={{ textAlign: 'left' }}>- Rolling Stone</p>
                        </div>
                    </div>
                </div>
            <div>
                <div className="contact-landing-page-section">
                    <h2>Contact</h2>
                    <ContactForm />
                </div>
               <Footer />
            </div>
    </div>
    )
};