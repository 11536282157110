import {createClient} from '@sanity/client'

const santyClient = createClient({
    projectId: "d3dhpvc9",
    dataset: "production",
    useCdn: true,
    apiVersion: '2023-05-03',
})

export default santyClient

// this is the file we use to talk to Sanity