import React, { useEffect, useState } from 'react';
import sanityClient from '../client.js';
import useImageLoader from '../custom_hooks/imageLoader.js';
import '../css/About.css'; 
import Footer from './Footer.js';
import Loader from './Loader.js';
import AboutHeroImage from '../images/Photo_by_Diaspora_Arts_5[optimized].jpg';
import AboutPageBackgound from '../images/allison-saeng-EnG_17GAMc4[optimized].jpg';

export default function About() {
  const [extendedBio, setExtendedBio] = useState(null);

  const imageUrlArray = [
    AboutHeroImage, AboutPageBackgound
];

const allImagesLoaded = useImageLoader(imageUrlArray);

  useEffect(() => {
    console.log("Fetching extended bio");
    sanityClient.fetch(`*[_type == "aboutSectionBio"]{ extendedbio }`)
      .then((data) => {
        console.log("Extended bio fetched");
        setExtendedBio(data[0].extendedbio);
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (allImagesLoaded) {
      console.log("All images loaded");
      const aboutHero = document.querySelector('.about-hero');
      const aboutPageBody = document.querySelector('.about-page-body');

      if (aboutHero) {
        aboutHero.style.backgroundImage = `url(${AboutHeroImage})`;
      }

      if (aboutPageBody) {
        aboutPageBody.style.backgroundImage = `linear-gradient(rgba(0, 46, 121, 0.883) 50%, rgba(20, 0, 121, 0.673)), url(${AboutPageBackgound})`;
      }
    }
  }, [allImagesLoaded]);

  if (!allImagesLoaded) {
    return <div><Loader loaded={false} /></div>;
  }

  return (
      <div className="about-page-body">
        <Loader loaded={true} />
        <div className="about-hero"></div>
        <h1 className="about-h1">About</h1>
        <div className="about-text">
          <p className="about-p">{extendedBio}</p>
        </div>
        
        <Footer />
      </div>
  );
}
