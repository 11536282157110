import { FaFacebook, FaInstagram } from "react-icons/fa";


export default function Footer () {
    return (
        <div className="footer">
        <div className="social-icons-footer" >
            <a href="https://www.facebook.com/FarahSirajMusic/" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={30} color="#FFFFFF"/>
            </a>
            <a href="https://www.instagram.com/farahsiraj/" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} color="#FFFFFF"/>
            </a>
        </div>
        <div className="website-by">
            <a href="https://www.kmsoundstrategies.com/dev" target="_blank">website by Kane Mathis</a>
        </div>
    </div>
    )
}