import React, { useState, useEffect } from 'react';
import '../css/PhotoCarousel.css';

export default function PhotoCarousel({ carouselImages = [], imageData }) {
  const [slideIndex, setSlideIndex] = useState(1);

  // useEffect(() => {
  //   showSlides(slideIndex);
  // }, [slideIndex, carouselImages]);

  const plusSlides = (n) => {
    let newIndex = slideIndex + n;
    if (newIndex > carouselImages.length) {
      newIndex = 1;
    } else if (newIndex < 1) {
      newIndex = carouselImages.length;
    }
    setSlideIndex(newIndex);
  };

  const currentSlide = (n) => {
    setSlideIndex(n);
  };

  // const showSlides = (n) => {
  //   let slides = document.getElementsByClassName("mySlides");
  //   let dots = document.getElementsByClassName("dot");

  //   for (let i = 0; i < slides.length; i++) {
  //     slides[i].style.display = "none";
  //   }

  //   for (let i = 0; i < dots.length; i++) {
  //     dots[i].className = dots[i].className.replace(" dot-active", "");
  //   }

  //   if (slides[n - 1]) {
  //     slides[n - 1].style.display = "block";
  //   }

  //   if (dots[n - 1]) {
  //     dots[n - 1].className += " dot-active";
  //   }
  // };

  return (
    <div>
      <div className="slideshow-container">
        {carouselImages.length > 0 ? (
          carouselImages.map((image, index) => (
            <div className="mySlides" key={index} style={{ display: index === slideIndex - 1 ? 'block' : 'none' }}>
              <div className="numbertext">{`${index + 1} / ${carouselImages.length}`}</div>
                <img className="fade" src={image} alt={imageData[index].alt} />
              <div className="text">{imageData[index].alt}</div>
            </div>
          ))
        ) : (
          <div className='no-images'>No images available</div>
        )}
        <a className="prev" onClick={() => plusSlides(-1)}>&#10094;</a>
        <a className="next" onClick={() => plusSlides(1)}>&#10095;</a>
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        {carouselImages.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === slideIndex - 1 ? 'dot-active' : ''}`}
            onClick={() => currentSlide(index + 1)}
          ></span>
        ))}
      </div>
    </div>
  );
}
