import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import '../css/Media.css';
import Loader from "./Loader.js";
import useImageLoader from "../custom_hooks/imageLoader.js";
import MediaHeroImage from "../images/Photo_by_Dario_Munoz_Leva[optimized].jpg";
import PhotoCarousel from "./PhotoCarousel";
import Footer from "./Footer";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function Media() {
  const [embedAlbums, setEmbedAlbums] = useState(null);
  const [carouselImages, setCarouselImages] = useState([]);
  const [imageUrlArray, setImageUrlArray] = useState([MediaHeroImage]);
  const [youTubeVideosArray, setYouTubeVideosArray] = useState(null);
  const [imageData, setImageData] = useState(null);

  const allImagesLoaded = useImageLoader(imageUrlArray);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "spotifyAlbums"]{
      title,
      albums
    }`).then((data) => {
      setEmbedAlbums(data[0].albums);
    })
    .catch(console.error);
  }, []);

  useEffect(() => {
    sanityClient.fetch(`*[_type == "media"]{
      images,
      youTubeVideos
    }`).then((data) => {
      console.log(data[0].images);
      setImageData(data[0].images)
      const fetchedImages = data[0].images.map(image => urlFor(image).fit('crop').url());
      setYouTubeVideosArray(data[0].youTubeVideos);
      setCarouselImages([...fetchedImages]);
      setImageUrlArray(prevArray => [...prevArray, ...fetchedImages]);
    })
    .catch(console.error);
  }, []);

  useEffect(() => {
    if (allImagesLoaded) {
      const mediaHero = document.querySelector('.media-hero');

      if (mediaHero) {
        mediaHero.style.backgroundImage = `url(${MediaHeroImage})`;
      }
    }
  }, [allImagesLoaded, imageUrlArray]);

  if (!allImagesLoaded) {
    return <div><Loader loaded={false} /></div>;
  }

  return (
    <div className="media-page-body">
      <Loader loaded={true} />
      <div className="media-hero"></div>
      <h1 className="media-h1">Media</h1>
      <div className="media-page-album-container">
        {embedAlbums && embedAlbums.map((album, index) => {
          return (
            <div key={index} dangerouslySetInnerHTML={{ __html: album.embedLink.code }} />
          );
        })}
      </div>
      <div className="media-youtube-container">
        {youTubeVideosArray && youTubeVideosArray.map((video, index)=>{
          return(
            <div key={index} className="youtube-video" dangerouslySetInnerHTML={{ __html: video.embedLink.code }}>

            </div>
          )
        })
        }
      </div>
      <PhotoCarousel carouselImages={carouselImages} imageData={imageData}/>
      <div className="spacer"></div>
      <Footer />
    </div>
  );
}
