import React from "react";
import { useEffect } from "react";
import ContactHeroImage from "../images/Farah_Siraj-Photo_by_Yasmina_Barbero_4[optimized].jpg"
import ContactForm from "./ContactForm";
import useImageLoader from "../custom_hooks/imageLoader";
import Loader from "./Loader";
import Footer from "./Footer";
import "../css/Contact.css"


export default function Contact () {
    const imageArray = [ContactHeroImage];
    const allImagesLoaded = useImageLoader(imageArray);

    useEffect(() => {
        
        if (allImagesLoaded) {
          const contactHero = document.querySelector('.contact-hero');
          
          if (contactHero) {
            contactHero.style.backgroundImage = `url(${ContactHeroImage})`;
          }
    
        }
      }, [allImagesLoaded]);
    
      if (!allImagesLoaded) {
        return <div><Loader loaded={false}/></div>;
      }
    return (
        <div className="contact-body">
          <Loader loaded={true} />
            <div className="contact-hero"></div> 
            <h1>Contact</h1>
            <div className="form-container">
                <ContactForm />
            </div>
            <Footer />
        </div>
    )
};